import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  REGISTER_SUCCESS,
  SHOW_MESSAGE,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET
} from "../../constants/ActionTypes";
import axios from "util/Api";
import { SERVER_VITRINE_URL } from "util/config";

export const setInitUrl = url => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const userSignUp = ({
  email,
  phone,
  password,
  confirm,
  name,
  type,
  history
}) => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios
      .post(
        "/auth/register",
        {
          email: email,
          password: password,
          password2: confirm,
          phone: phone,
          name: name,
          type: type
        },
        { baseURL: SERVER_VITRINE_URL + "api" }
      )
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: SHOW_MESSAGE,
            payload: `merci pour votre inscription, vous pouvez
              désormais vous connecter et compléter vos informations personnelles en
              attendant l’activation de votre compte par nos administrateurs`
          });
          history.push("/");
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const userSignIn = ({ email, password }) => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios
      .post(
        "/login",
        {
          email: email,
          password: password
        },
        { baseURL: SERVER_VITRINE_URL + "api" }
      )
      .then(({ data }) => {
        if (!data.error) {
          localStorage.setItem("token", data.token);
          axios.defaults.headers.common["Authorization"] = data.token;
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: USER_TOKEN_SET, payload: data.token });
          dispatch({ type: USER_DATA, payload: data.user });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const getUser = () => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios
      .get("/current_user", { baseURL: SERVER_VITRINE_URL + "api" })
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: USER_DATA, payload: data.user });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const userSignOut = () => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    setTimeout(() => {
      localStorage.removeItem("token");
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: SIGNOUT_USER_SUCCESS });
    }, 2000);
  };
};
