import React, {Component} from "react";
import {connect} from "react-redux";
import {Avatar, Popover} from "antd";
import {userSignOut} from "appRedux/actions/Auth";
import axios from "axios";
import {SERVER_IMAGES_URL} from 'util/config'
// const SERVER_IMAGES_URL = 'http://localhost:5000/uploads/';

class UserProfile extends Component {

  constructor(){
    super();
    this.state = {
      profile_pic : 'https://via.placeholder.com/150x150',
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.authUser ) {
      setTimeout(() => {
        const {authUser} = this.props;
        if(authUser && authUser.type === 'library'){
          this.getLibraryInfos();
        }else{
          if(authUser && authUser.type === 'publisher'){
            this.getPublisherInfo();
          }else{

          }
        }
      }, 3000);
    }
  }

  getLibraryInfos = () => {
    axios
      .get("/libraries/current")
      .then(({ data }) => {
        if (data && data.success && data.library) {
          this.setState({
            profile_pic: !data.library.profile_pic ? " " : SERVER_IMAGES_URL + 'libraries/profile/' +data.library.profile_pic
          });
        }
      })
      .catch(err => {
        console.error(err);
      });
  };

  getPublisherInfo = () => {
    axios
      .get("/publishers/current")
      .then(({ data }) => {
        if (data && data.success && data.publisher) {
          this.setState({
            profile_pic: !data.publisher.profile_pic ? " " : SERVER_IMAGES_URL + 'publishers/profile/' +data.publisher.profile_pic
          });
        }
      })
      .catch(err => {
        console.error(err);
      });
  };


  render() {



    const {authUser} = this.props;
    const {profile_pic} = this.state;


    const userMenuOptions = (
      <ul className="gx-user-popover">
        <li>Mon profil</li>
        <li onClick={() => this.props.userSignOut()}>Déconnexion
        </li>
      </ul>
    );

    return (

      <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
        <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
          <Avatar src={profile_pic}
                  className="gx-size-40 gx-pointer gx-mr-3" alt=""/>
          <span className="gx-avatar-name">{authUser ? authUser.username : "Loading"}<i
            className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/></span>
        </Popover>
      </div>

    )

  }
}

const mapStateToProps = ({auth}) => {
  const {authUser} = auth;
  return {authUser}
};

export default connect(mapStateToProps, {userSignOut})(UserProfile);
