import React from "react";
import { Link } from "react-router-dom";
import terms, { colonne, largeur_term } from "./data";
import { Card, Typography, List, Avatar } from "antd";

const TermsAndConditions = props => {
  return (
    <Card
      bordered={false}
      style={{ overflow: "scroll", height: "100%", marginBottom: "0" }}
    >
      <Typography.Title level={1}>
        <span style={{ color: "#038fde" }}>Conditions d'utilisation (CGU) </span>
      </Typography.Title>

      <List
        grid={{ gutter: largeur_term, column: colonne }}
        itemLayout="horizontal"
        dataSource={terms}
        size="small"
        footer={<Link to={`/${props.redirect}`}>Revenir à l'inscription</Link>}
        renderItem={(term, i) => (
          <List.Item key={i}>
            <List.Item.Meta
              avatar={
                <Avatar src="https://banner2.cleanpng.com/20180422/taw/kisspng-bullet-computer-icons-arrow-symbol-5adc978dd94038.2570881715244061578899.jpg" />
              }
              title={
                <a style={{ color: "#038fde" }} href="#">
                  {term.title}
                </a>
              }
              description={
                <span style={{ color: "rgba(0,0,0, .4)" }}>
                  {term.description}
                </span>
              }
            />
            {term.content}
          </List.Item>
        )}
      />
    </Card>
  );
};

export default TermsAndConditions;
