module.exports = {
  footerText:
    "Phicra Lab © " + new Date().getFullYear() + " - All rights reserved",
   SERVER_IMAGES_URL: "https://api.booxium.com/uploads/",
   SERVER_VITRINE_URL: "https://api.booxium.com/",
   SERVER_URL: "https://api.booxium.com"
  //SERVER_VITRINE_URL: "http://localhost:5000/",
  //SERVER_IMAGES_URL: "http://localhost:5000/uploads/",
  //SERVER_URL: "http://localhost:5000"
};

