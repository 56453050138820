import React, { Component } from "react";
import { connect } from "react-redux";
import { Menu, Icon } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";

import Auxiliary from "util/Auxiliary";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
const SubMenu = Menu.SubMenu;

class SidebarContent extends Component {
  getNoHeaderClass = navStyle => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  getNavStyleSubMenuClass = navStyle => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  render() {
    const { themeType, navStyle, pathname } = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split("/")[1];
    const { authUser } = this.props;
    return (
      <Auxiliary>
        <SidebarLogo />
        <div className="gx-sidebar-content">
          <div
            className={`gx-sidebar-notifications ${this.getNoHeaderClass(
              navStyle
            )}`}
          >
            <UserProfile />
            <AppsNavigation />
          </div>
          <CustomScrollbars className="gx-layout-sider-scrollbar">
            {/*Admin menu*/}
            {authUser && authUser.type == "admin" && (
              <Menu
                defaultOpenKeys={[defaultOpenKeys]}
                selectedKeys={[selectedKeys]}
                theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
                mode="inline"
              >
                <Menu.Item key="sample">
                  <Link to="/sample">
                    <i className="icon icon-widgets" />
                    <IntlMessages id="sidebar.samplePage" />
                  </Link>
                </Menu.Item>
                <SubMenu
                  key="books"
                  className={this.getNavStyleSubMenuClass(navStyle)}
                  title={
                    <span>
                      <i className="icon icon-all-contacts" />
                      <IntlMessages id="sidebar.components.books" />
                    </span>
                  }
                >
                  <Menu.Item key="components/general/button">
                    <Link to="/admin/books/list">
                      <IntlMessages id="sidebar.books.list" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="components/general/icon">
                    <Link to="/admin/books/add">
                      <IntlMessages id="sidebar.books.add" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="admin/books/massif">
                    <Link to="/admin/books/massif/add">
                      <IntlMessages id="sidebar.books.add.massif" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="admin/books/massifSalon">
                    <Link to="/admin/books/massifSalon">
                      {"Ajout massif salon"}
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="components/general/buttons">
                    <Link to="/admin/unreferencedbooks/requests">
                      <IntlMessages id="sidebar.components.unreferencedbooks" />
                    </Link>
                  </Menu.Item>
                </SubMenu>
                <SubMenu
                  key="users"
                  className={this.getNavStyleSubMenuClass(navStyle)}
                  title={
                    <span>
                      <i className="icon icon-user" />
                      <IntlMessages id="sidebar.components.users" />
                    </span>
                  }
                >
                  <Menu.Item key="components/general/button">
                    <Link to="/admin/users/admins">
                      <IntlMessages id="sidebar.admins.listadmin" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="components/general/icon">
                    <Link to="/admin/users/bookstores">
                      <IntlMessages id="sidebar.admins.bookstores" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="components/general/ixcon">
                    <Link to="/admin/users/publishinghouses">
                      <IntlMessages id="sidebar.admins.publishinghouses" />
                    </Link>
                  </Menu.Item>
                </SubMenu>
                <SubMenu
                  key="admin.requests"
                  className={this.getNavStyleSubMenuClass(navStyle)}
                  title={
                    <span>
                      <i className="icon icon-search" />
                      <IntlMessages id="sidebar.library.requests" />
                    </span>
                  }
                >
                  <Menu.Item key="admin.orders.new">
                    <Link to="/admin/requests/user/private-requests">
                      <IntlMessages id="sidebar.admin.user.requests.private" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="admin.orders.confirmed">
                    <Link to="/admin/requests/user/public-requests">
                      <IntlMessages id="sidebar.admin.user.requests.public" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="admin.private.requests">
                    <Link to="/admin/requests/library/private-requests">
                      <IntlMessages id="sidebar.admin.library.requests.private" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="admin.public.requests">
                    <Link to="/admin/requests/library/public-requests">
                      <IntlMessages id="sidebar.admin.library.requests.public" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="admin.get.new.orders">
                    <Link to="/admin/requests/getorders/getneworders">
                      <IntlMessages id="New orders" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="admin.salon.requests">
                    <Link to="/admin/requests/salon/requests">
                      <IntlMessages id="sidebar.admin.salon.requests" />
                    </Link>
                  </Menu.Item>
                </SubMenu>
                <SubMenu
                  key="library.orders"
                  className={this.getNavStyleSubMenuClass(navStyle)}
                  title={
                    <span>
                      <i className="icon icon-shopping-cart" />
                      <IntlMessages id="sidebar.library.orders" />
                    </span>
                  }
                >
                  <Menu.Item key="library.orders.confirmed">
                    <Link to="/admin/orders/deliveries">
                      <IntlMessages id="sidebar.library.orders.delivery" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="library.orders.libraries.orders">
                    <Link to="/admin/orders/libraries-orders">
                      <IntlMessages id="sidebar.library.libraries.orders" />
                    </Link>
                  </Menu.Item>
                </SubMenu>
                <SubMenu
                  key="sidebar.shipping"
                  className={this.getNavStyleSubMenuClass(navStyle)}
                  title={
                    <span>
                      <i className="icon icon-orders" />
                      <IntlMessages id="sidebar.shipping" />
                    </span>
                  }
                >
                  <Menu.Item key="library.shipping.list">
                    <Link to="/admin/shipping/list">
                      <IntlMessages id="sidebar.shipping.list" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="sidebar.shipping.zones">
                    <Link to="/admin/shipping/zones">
                      <IntlMessages id="sidebar.shipping.zones" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="sidebar.shipping.livraisonpacks">
                    <Link to="/admin/shipping/packlivraison">
                      <IntlMessages id="sidebar.shipping.livraisonpacks" />
                    </Link>
                  </Menu.Item>
                </SubMenu>
                <SubMenu
                  key="sidebar.subscription"
                  className={this.getNavStyleSubMenuClass(navStyle)}
                  title={
                    <span>
                      <i className="icon icon-card" />
                      <IntlMessages id="sidebar.subscription" />
                    </span>
                  }
                >
                  <Menu.Item key="sidebar.subscription.librarypacks">
                    <Link to="/admin/subscriptions/packs">
                      <IntlMessages id="sidebar.subscription.librarypacks" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="sidebar.subscription.librarysubscriptions">
                    <Link to="/admin/subscriptions/subscriptions">
                      <IntlMessages id="sidebar.subscription.librarysubscriptions" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="sidebar.subscription.publisherpacks">
                    <Link to="/admin/subscriptions/packpublisher">
                      <IntlMessages id="sidebar.subscription.publisherpacks" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="sidebar.subscription.publishersubscriptions">
                    <Link to="/admin/subscriptions/subscriptionspublishers">
                      <IntlMessages id="sidebar.subscription.publishersubscriptions" />
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="sidebar.subscription.offer">
                    <Link to="/admin/subscriptions/offerpack">
                      <IntlMessages id="sidebar.subscription.offer" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="sidebar.subscription.meanspayment">
                    <Link to="/admin/subscriptions/meanspayment">
                      <IntlMessages id="sidebar.subscription.meanspayment" />
                    </Link>
                  </Menu.Item>
                </SubMenu>
                <SubMenu
                  key="sidebar.settings"
                  className={this.getNavStyleSubMenuClass(navStyle)}
                  title={
                    <span>
                      <i className="icon icon-setting" />
                      <IntlMessages id="sidebar.settings" />
                    </span>
                  }
                >
                  <Menu.Item key="sidebar.settings.tags">
                    <Link to="/admin/settings/tags">
                      <IntlMessages id="sidebar.settings.tags" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="sidebar.settings.categories">
                    <Link to="/admin/settings/categories">
                      <IntlMessages id="sidebar.settings.categories" />
                    </Link>
                  </Menu.Item>
                </SubMenu>
                <Menu.Item key="infoPage">
                  {/* <Link to="/admin/infoPage">
                    <i className="icon icon-badge" />
                    Comment ça marche
                  </Link> */}
                </Menu.Item>
              </Menu>
            )}

            {/* Library Menu*/}
            {authUser && authUser.type == "library" && (
              <Menu
                defaultOpenKeys={[defaultOpenKeys]}
                selectedKeys={[selectedKeys]}
                theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
                mode="inline"
              >
                <SubMenu
                  key="myprofile"
                  className={this.getNavStyleSubMenuClass(navStyle)}
                  title={
                    <span>
                      <i className="icon icon-profile" />
                      <IntlMessages id="sidebar.profile" />
                    </span>
                  }
                >
                  <Menu.Item key="components/general/myprofile">
                    <Link to="/library/profile">
                      <IntlMessages id="sidebar.profile.myprofile" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="components/general/editprofile">
                    <Link to="/library/editprofile">
                      <IntlMessages id="sidebar.profile.editprofile" />
                    </Link>
                  </Menu.Item>
                  {/* <Menu.Item key="components/general/confidentiality">
                    <Link to="/library/confidentiality">
                      <IntlMessages id="sidebar.profile.confidentiality" />
                    </Link>
                  </Menu.Item> */}
                  <Menu.Item key="components/general/openingtime">
                    <Link to="/library/openingtime">
                      <IntlMessages id="sidebar.profile.openingtime" />
                    </Link>
                  </Menu.Item>
                </SubMenu>
                <SubMenu
                  key="library.books"
                  className={this.getNavStyleSubMenuClass(navStyle)}
                  title={
                    <span>
                      <i className="icon icon-all-contacts" />
                      <IntlMessages id="sidebar.library.biblio" />
                    </span>
                  }
                >
                  <Menu.Item key="library.books.allBooks">
                    <Link to="/library/books/list">
                      <IntlMessages id="sidebar.library.allBooks" />
                    </Link>
                  </Menu.Item>
 			<Menu.Item key="library.books.available">
                    <Link to="/library/books/available">
                      <IntlMessages id="sidebar.library.available" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="library.books.vitrine">
                    <Link to="/library/books/vitrine">
                      <IntlMessages id="sidebar.library.vitrine" />
                    </Link>
                  </Menu.Item>
                 
                 
{/* <Menu.Item key="library.books.unreferencedBooks">
                    <Link to="/library/unreferencedbooks/requests">
                      <IntlMessages id="sidebar.components.unreferencedbooks" />
                    </Link>
                  </Menu.Item>*/}

                </SubMenu>
                <SubMenu
                  key="library.requests"
                  className={this.getNavStyleSubMenuClass(navStyle)}
                  title={
                    <span>
                      <i className="icon icon-search" />
                      <IntlMessages id="sidebar.library.requests" />
                    </span>
                  }
                >
                  <Menu.Item key="library.orders.new">
                    <Link to="/library/orders/private-requests">
                      <IntlMessages id="sidebar.library.requests.private" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="library.orders.confirmed">
                    <Link to="/library/orders/public-requests">
                      <IntlMessages id="sidebar.library.requests.public" />
                    </Link>
                  </Menu.Item>
                </SubMenu>
                <SubMenu
                  key="library.orders"
                  className={this.getNavStyleSubMenuClass(navStyle)}
                  title={
                    <span>
                      <i className="icon icon-shopping-cart" />
                      <IntlMessages id="sidebar.library.orders" />
                    </span>
                  }
                >
             {/*      <Menu.Item key="library.orders.new">
                    <Link to="/library/orders/reservations">
                      <IntlMessages id="sidebar.library.orders.reservations" />
                    </Link>
                  </Menu.Item>  */}

                  <Menu.Item key="library.orders.confirmed">
                    <Link to="/library/orders/deliveries">
                      <IntlMessages id="sidebar.library.orders.delivery" />
                    </Link>
                  </Menu.Item>
                </SubMenu>
 {              
	 	<SubMenu
                  key="library.providers"
                  className={this.getNavStyleSubMenuClass(navStyle)}
                  title={
                    <span>
                      <i className="icon icon-orders" />
                    Acheter
                    </span>
                  }
                > 
                   <Menu.Item key="library.provider.addquery">
                    <Link to="/library/providers/addquery">
                     Nouvelle commande

                    </Link>
                 </Menu.Item> 
    <Menu.Item key="library.provider.public">
                    <Link to="/library/providers/queries/public">
                      Requêtes publiques
                    </Link>
                  </Menu.Item> 

                   <Menu.Item key="library.provider.private">
                    <Link to="/library/providers/queries/private">
                      Mon panier
                    </Link>
                  </Menu.Item> 
    <Menu.Item key="library.provider.orders">
                    <Link to="/library/providers/orders">
                      <IntlMessages id="sidebar.library.provider.orders" />
                    </Link>
                  </Menu.Item> 
                </SubMenu> 
    }

                <SubMenu
                  key="sidebar.subscription"
                  className={this.getNavStyleSubMenuClass(navStyle)}
                  title={
                    <span>
                      <i className="icon icon-card" />
                      <IntlMessages id="sidebar.subscription" />
                    </span>
                  }
                >
                  <Menu.Item key="sidebar.subscription.packs">
                    <Link to="/library/subscriptions/packs">
                      <IntlMessages id="sidebar.subscription.packs" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="sidebar.subscription.subscriptions">
                    <Link to="/library/subscriptions/subscriptions">
                      <IntlMessages id="sidebar.subscription.subscriptions" />
                    </Link>
                  </Menu.Item>
                </SubMenu>
                <Menu.Item key="infoPage">
                  {/* <Link to="/library/infoPage">
                    <i className="icon icon-badge" />
                    Comment ça marche
                  </Link> */}
                </Menu.Item>
              </Menu>
            )}

            {authUser && authUser.type == "publisher" && (
              <Menu
                defaultOpenKeys={[defaultOpenKeys]}
                selectedKeys={[selectedKeys]}
                theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
                mode="inline"
              >
                <SubMenu
                  key="myprofile"
                  className={this.getNavStyleSubMenuClass(navStyle)}
                  title={
                    <span>
                      <i className="icon icon-profile" />
                      <IntlMessages id="sidebar.profile" />
                    </span>
                  }
                >
                  <Menu.Item key="components/general/myprofile">
                    <Link to="/publisher/profile">
                      <IntlMessages id="sidebar.profile.myprofile" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="components/general/editprofile">
                    <Link to="/publisher/editprofile">
                      <IntlMessages id="sidebar.profile.editprofile" />
                    </Link>
                  </Menu.Item>
                  {/* <Menu.Item key="components/general/confidentiality">
                    <Link to="/publisher/confidentiality">
                      <IntlMessages id="sidebar.profile.confidentiality" />
                    </Link>
                  </Menu.Item> */}
                </SubMenu>
                <SubMenu
                  key="books"
                  className={this.getNavStyleSubMenuClass(navStyle)}
                  title={
                    <span>
                      <i className="icon icon-all-contacts" />
                      <IntlMessages id="sidebar.components.books" />
                    </span>
                  }
                >
                  <Menu.Item key="components/general/button">
                    <Link to="/publisher/books/list">
                      <IntlMessages id="sidebar.books.list" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="components/general/icon">
                    <Link to="/publisher/books/add">
                      <IntlMessages id="sidebar.books.add" />
                    </Link>
                  </Menu.Item>
                </SubMenu>

                {/* <Menu.Item key="annuaire">
                  <Link to="/publisher/libraries">
                    <i className="icon icon-all-contacts" />
                    Librairies
                  </Link>
                </Menu.Item>
                <SubMenu
                  key="publisher.requests"
                  className={this.getNavStyleSubMenuClass(navStyle)}
                  title={
                    <span>
                      <i className="icon icon-search" />
                      <IntlMessages id="sidebar.library.requests" />
                    </span>
                  }
                >
                  <Menu.Item key="publisher.requests.private">
                    <Link to="/publisher/orders/private-requests">
                      <IntlMessages id="sidebar.library.requests.private" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="publisher.requests.public">
                    <Link to="/publisher/orders/public-requests">
                      <IntlMessages id="sidebar.library.requests.public" />
                    </Link>
                  </Menu.Item>
                </SubMenu>
                <Menu.Item key="orders">
                  <Link to="/publisher/orders">
                    <i className="icon icon-shopping-cart" />
                    Commandes
                  </Link>
                </Menu.Item>
                <Menu.Item key="deliveries">
                  <Link to="/publisher/deliveries">
                    <i className="icon icon-orders" />
                    Mes livraisons
                  </Link>
                </Menu.Item>

                <SubMenu
                  key="sidebar.subscription"
                  className={this.getNavStyleSubMenuClass(navStyle)}
                  title={
                    <span>
                      <i className="icon icon-card" />
                      <IntlMessages id="sidebar.subscription" />
                    </span>
                  }
                >
                  <Menu.Item key="sidebar.subscription.packs">
                    <Link to="/publisher/subscriptions/packs">
                      <IntlMessages id="sidebar.subscription.packs" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="sidebar.subscription.subscriptions">
                    <Link to="/publisher/subscriptions/subscriptions">
                      <IntlMessages id="sidebar.subscription.subscriptions" />
                    </Link>
                  </Menu.Item>
                </SubMenu>
                <Menu.Item key="infoPage">
                  <Link to="/publisher/infoPage">
                    <i className="icon icon-badge" />
                    Comment ça marche
                  </Link> 
                </Menu.Item> */}
              </Menu>
            )}

            {authUser && authUser.type == "livreur" ? (
              authUser.type_livraison === "pro" ? (
                <Menu
                  defaultOpenKeys={[defaultOpenKeys]}
                  selectedKeys={[selectedKeys]}
                  theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
                  mode="inline"
                >
                  <Menu.Item key="orders">
                    <Link to="/livreurpro/list">
                      <i className="icon icon-shopping-cart" />
                      Commandes
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="shipping">
                    <Link to="/livreurpro/mylist">
                      <i className="icon icon-orders" />
                      Mes livraisons
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="infoPage">
                    {/* <Link to="/infoPage">
                    <i className="icon icon-badge" />
                    Comment ça marche
                  </Link> */}
                  </Menu.Item>
                </Menu>
              ) : (
                <Menu
                  defaultOpenKeys={[defaultOpenKeys]}
                  selectedKeys={[selectedKeys]}
                  theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
                  mode="inline"
                >
                  <Menu.Item key="orders">
                    <Link to="/livreur/list">
                      <i className="icon icon-shopping-cart" />
                      Commandes
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="shipping">
                    <Link to="/livreur/mylist">
                      <i className="icon icon-orders" />
                      Mes livraisons
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="infoPage">
                    {/* <Link to="/infoPage">
                    <i className="icon icon-badge" />
                    Comment ça marche
                  </Link> */}
                  </Menu.Item>
                </Menu>
              )
            ) : (
              <></>
            )}
          </CustomScrollbars>
        </div>
      </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({ settings, auth }) => {
  const { navStyle, themeType, locale, pathname } = settings;
  const { authUser } = auth;
  return { navStyle, themeType, locale, pathname, authUser };
};
export default connect(mapStateToProps)(SidebarContent);
