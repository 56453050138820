import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";
const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route
        path={`${match.url}admin`}
        component={asyncComponent(() => import("./admin"))}
      />
      <Route
        path={`${match.url}library`}
        component={asyncComponent(() => import("./library"))}
      />
      <Route
        path={`${match.url}publisher`}
        component={asyncComponent(() => import("./publisher"))}
      />
      <Route
        path={`${match.url}livreurpro`}
        component={asyncComponent(() => import("./livreur/pro"))}
      />
      <Route
        path={`${match.url}livreur`}
        component={asyncComponent(() => import("./livreur"))}
      />
    </Switch>
  </div>
);

export default App;
