import React from "react";
import { Button, Checkbox, Form, Input, Icon, Radio } from "antd";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { userSignUp } from "../appRedux/actions/Auth";

import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";
import backgroundImage from "../assets/images/home_background.png";
const FormItem = Form.Item;

class SignUp extends React.Component {
  state = {
    confirmDirty: false
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        values.history = this.props.history;
        this.props.userSignUp(values);
      }
    });
  };

  componentDidUpdate() {
    if (this.props.token !== null) {
      this.props.history.push("/");
    }
  }

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback(
        "Les deux mots de passe que vous avez saisi ne sont pas similaires!"
      );
    } else {
      callback();
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div
        className="gx-app-login-wrap"
        style={{
          backgroundImage: `url(${backgroundImage})`
        }}
      >
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg" />
              <div className="gx-app-logo-wid">
                <h1>
                  <IntlMessages id="app.userAuth.signUp" />
                </h1>
                <p>
                  <IntlMessages id="app.userAuth.bySigning" />
                </p>
                <p>
                  <IntlMessages id="app.userAuth.getAccount" />
                </p>
              </div>
              <div className="gx-app-logo">
                <img alt="example" src={require("assets/images/logo.png")} />
              </div>
            </div>

            <div className="gx-app-login-content">
              <Form
                onSubmit={this.handleSubmit}
                className="gx-signup-form gx-form-row0"
              >
                <FormItem>
                  {getFieldDecorator("type", {
                    initialValue: "library",
                    rules: [
                      {
                        required: true,
                        message: `Veuillez indiquer si vous êtes une libraririe ou une maison d'édition!`
                      }
                    ]
                  })(
                    <Radio.Group onChange={this.onTypeChange}>
                      <Radio value="library">Librairie</Radio>
                      <Radio value="publisher">Maison d'édition</Radio>
                    </Radio.Group>
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator("name", {
                    rules: [
                      {
                        required: true,
                        message: "Veuillez saisir le nom de votre entreprise!"
                      }
                    ]
                  })(<Input placeholder="Nom de la librairie ou m. d'édition" />)}
                </FormItem>
                <FormItem hasFeedback>
                  {getFieldDecorator("email", {
                    rules: [
                      {
                        required: true,
                        type: "email",
                        message: `Veuillez saisir un email valide!`
                      }
                    ]
                  })(<Input placeholder="Email" />)}
                </FormItem>
                <FormItem hasFeedback>
                  {getFieldDecorator("phone", {
                    rules: [
                      {
                        required: true,
                        message: `Veuillez saisir votre N° de téléphone!`
                      }
                    ]
                  })(<Input placeholder="N° de téléphone" />)}
                </FormItem>
                <FormItem hasFeedback>
                  {getFieldDecorator("password", {
                    rules: [
                      {
                        required: true,
                        message: "Veuillez saisir votre mot de passe!"
                      },
                      {
                        min: 4,
                        message:
                          "Le mot de passe doit contenir au minimum 4 caractères!"
                      },
                      {
                        validator: this.validateToNextPassword
                      }
                    ]
                  })(
                    <Input.Password
                      prefix={
                        <Icon
                          type="lock"
                          style={{ color: "rgba(0,0,0,.25)" }}
                        />
                      }
                      type="password"
                      placeholder="Mot de passe"
                    />
                  )}
                </FormItem>
                <FormItem hasFeedback>
                  {getFieldDecorator("confirm", {
                    rules: [
                      {
                        required: true,
                        message: "Veuillez confirmer votre mot de passe!"
                      },
                      {
                        validator: this.compareToFirstPassword
                      }
                    ]
                  })(
                    <Input.Password
                      onBlur={this.handleConfirmBlur}
                      prefix={
                        <Icon
                          type="lock"
                          style={{ color: "rgba(0,0,0,.25)" }}
                        />
                      }
                      type="password"
                      placeholder="Confirmez le mot de passe"
                    />
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator("remember", {
                    valuePropName: "checked",
                    initialValue: true
                  })(
                    <Checkbox>
                      <IntlMessages id="appModule.iAccept" />
                    </Checkbox>
                  )}
                  <span className="gx-signup-form-forgot gx-link">
                    <Link to="/terms&conditions">
                      <IntlMessages id="appModule.termAndCondition" />
                    </Link>
                  </span>
                </FormItem>
                {/*<FormItem>*/}
                {/*  {getFieldDecorator('remember', {*/}
                {/*    valuePropName: 'checked',*/}
                {/*    initialValue: true,*/}
                {/*  })(*/}
                {/*    <Checkbox><IntlMessages id="appModule.iAccept"/></Checkbox>*/}
                {/*  )}*/}
                {/*  <span className="gx-link gx-signup-form-forgot"><IntlMessages*/}
                {/*    id="appModule.termAndCondition"/></span>*/}
                {/*</FormItem>*/}
                <FormItem>
                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                    <IntlMessages id="app.userAuth.signUp" />
                  </Button>
                  <span>
                    <IntlMessages id="app.userAuth.or" />
                  </span>{" "}
                  <Link to="/signin">
                    <IntlMessages id="app.userAuth.signIn" />
                  </Link>
                </FormItem>
              </Form>
            </div>
            <InfoView />
          </div>
        </div>
      </div>
    );
  }
}

const WrappedSignUpForm = Form.create()(SignUp);

const mapStateToProps = ({ auth }) => {
  const { token } = auth;
  return { token };
};

export default connect(mapStateToProps, { userSignUp })(WrappedSignUpForm);
