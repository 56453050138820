import React from 'react';
import CircularProgress from "components/CircularProgress/index";
import {message , Modal } from 'antd';
import Auxiliary from "util/Auxiliary";
import {connect} from "react-redux";
import {hideMessage} from "appRedux/actions/Common";

class InfoView extends React.Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.error || nextProps.message || nextProps.displayMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 3000);
    }
  }

  render() {
    const {error, loading, displayMessage} = this.props;

    const showError = (message)=>{
      Modal.error({
        title: 'Erreur',
        content: message,
      });
    };

    const showSuccess = (message)=>{
      Modal.success({
        title: 'Succès',
        content: message,
      });
    };

    return (
      <Auxiliary>
        {loading && <div className="gx-loader-view">
          <CircularProgress/>
        </div>}
        {displayMessage && showSuccess(displayMessage)}
        {error && showError(error)  }
      </Auxiliary>
    );
  }
}

const mapStateToProps = ({commonData}) => {
  const {error, loading} = commonData;
  const displayMessage = commonData.message;
  return {error, loading, displayMessage};
};

export default connect(mapStateToProps, {hideMessage})(InfoView);
